import React, { Component } from "react";
import Notification from "./component_notification";

import notifications from "./helpers/notifications";

import * as SurveyPlugin from "survey-knockout";
import * as SurveyCreator from "survey-creator";

import auth_connector from "./api/auth";
import {
  warningTemplate,
  getActiveToolboxComponents,
  getQuestion,
  getDependencies,
  getQuestionsInPage,
} from "./helpers/template_utils";

import logo from "./static/logo/logo-chmedic2.svg";

import "bootstrap/dist/css/bootstrap.css";
import "survey-knockout/survey.css";
import "survey-creator/survey-creator.css";
import "./index.css";

import "nouislider/distribute/nouislider.min.css";

//old components
import leadmatrix4left from "./custom_components/leadmatrix4left";
import leadmatrix4right from "./custom_components/leadmatrix4right";
import leadmatrix8combined from "./custom_components/leadmatrix8combined";
import inputInteger from "./custom_components/inputinteger";
import inputPercents from "./custom_components/inputpercents";

//v2
import nouislider from "./custom_widgets/nouislider";
import inputcustom from "./custom_widgets/inputcustom";
import clearanswer from "./custom_widgets/clearanswer";
import inputdate from "./custom_widgets/inputdate";
//lead probes
import leadprobes4v2 from "./custom_components/leadprobes4v2";
import leadprobes8v2 from "./custom_components/leadprobes8v2";
//lead matrix
import leadmatrix8v2 from "./custom_components/leadmatrix8v2";
import pacientAge from "./custom_components/pacient_age";

//predefined questions
import yesno from "./custom_components/yesno";
import yesnodontknow from "./custom_components/yesnodontknow";
import gender from "./custom_components/gender";
import birthdate from "./custom_components/birthdate";
import proceduredate from "./custom_components/proceduredate";

import TemplateExplorer from "./template_explorer";
import AutoSavePopup from "./helpers/autosavepopup";
import CustomConfirm from "./helpers/customconfirm";
import DeleteElementPopup from "./custom_widgets/delete_element_popup/deleteelementpopup";

const supportedLocales = [
  { code: "en", name: "english" },
  { code: "de", name: "deutsch" },
  { code: "fr", name: "français" },
  { code: "it", name: "italiano" },
];

const getPacientAge = function () {
  return 69; //for testing
};

SurveyPlugin.FunctionFactory.Instance.register("getPacientAge", getPacientAge);

const getPacientBirthdate = function () {
  return "1989-10-12"; //for testing
};

SurveyPlugin.FunctionFactory.Instance.register(
  "getPacientBirthdate",
  getPacientBirthdate
);

const getProcedureDate = function () {
  return "1992-10-12"; //for testing
};

SurveyPlugin.FunctionFactory.Instance.register(
  "getProcedureDate",
  getProcedureDate
);

function calcFullYearDifference(params) {
  if (!params || !params[0] || !params[1]) return undefined;

  let dateArray = params[0].split("-");
  const birthdate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
  dateArray = params[1].split("-");
  const proceduredate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);

  let yearDifference = proceduredate.getFullYear() - birthdate.getFullYear();

  if (
    birthdate.getMonth() > proceduredate.getMonth() ||
    (birthdate.getMonth() === proceduredate.getMonth() &&
      birthdate.getDate() > proceduredate.getDate())
  ) {
    yearDifference--;
  }

  return yearDifference;
}

SurveyPlugin.FunctionFactory.Instance.register(
  "calcFullYearDifference",
  calcFullYearDifference
);

function isDateLessThanCurrent(params) {
  if (params.length < 1) return false;
  var date = params[0];
  debugger;
  if (typeof date == "string") date = Date.parse(date);
  return date <= new Date();
}

SurveyPlugin.FunctionFactory.Instance.register(
  "isDateLessThanCurrent",
  isDateLessThanCurrent
);

//old
SurveyPlugin.ComponentCollection.Instance.add(leadmatrix4left);
SurveyPlugin.ComponentCollection.Instance.add(leadmatrix4right);
SurveyPlugin.ComponentCollection.Instance.add(leadmatrix8combined);
SurveyPlugin.ComponentCollection.Instance.add(inputInteger);
SurveyPlugin.ComponentCollection.Instance.add(inputPercents);

//v2
SurveyPlugin.CustomWidgetCollection.Instance.add(nouislider, "customtype");
SurveyPlugin.CustomWidgetCollection.Instance.add(inputcustom, "customtype");
SurveyPlugin.CustomWidgetCollection.Instance.add(clearanswer, "customtype");
SurveyPlugin.CustomWidgetCollection.Instance.add(inputdate, "customtype");
//lead probes
SurveyPlugin.ComponentCollection.Instance.add(leadprobes4v2);
SurveyPlugin.ComponentCollection.Instance.add(leadprobes8v2);
//lead matrix
SurveyPlugin.ComponentCollection.Instance.add(leadmatrix8v2);

SurveyPlugin.ComponentCollection.Instance.add(pacientAge);

SurveyPlugin.JsonObject.metaData.findProperty(
  "questionbase",
  "name"
).readOnly = true;

//Expressions
SurveyPlugin.Serializer.addProperty("itemvalue", {
  name: "calcValue",
});

const translations = SurveyCreator.localization.getLocale("");
translations.pe.value = "Key";
translations.pe.locale = "Selected language";
translations.ed.defaultLocale = "{0}";

const getCalcValue = function (params) {
  let question = !!this.row
    ? this.row.getQuestionByColumnName(params[0])
    : this.survey.getQuestionByName(params[0])
    ? this.survey.getQuestionByName(params[0])
    : this.question;

  if (!question) return 0;

  const questionType = question.getType();

  if (questionType === "inputcustom") {
    return parseFloat(question.value);
  }

  let choice = question.selectedItem;

  return !!choice ? Number(choice.calcValue) : 0;
};

//keeped for existing surveys
SurveyPlugin.FunctionFactory.Instance.register("getCalcValue", getCalcValue);
SurveyPlugin.FunctionFactory.Instance.register("gcv", getCalcValue);

const nullCounter = (value) => {
  let counter = 0;

  if (value === undefined || value === null) {
    counter++;
  } else if (Array.isArray(value)) {
    for (var i = 0; i < value.length; i++) {
      counter += nullCounter(value[i]);
    }
  }

  return counter;
};

const countNulls = function (params) {
  let counter = nullCounter(params);

  return counter;
};

SurveyPlugin.FunctionFactory.Instance.register("countNulls", countNulls);

const roundFunc = function (params) {
  if (!isFinite(params[0])) return params[0];

  const exponent = Math.pow(10, params[1]);
  return Math.round(params[0] * exponent) / exponent;
};

SurveyPlugin.FunctionFactory.Instance.register("round", roundFunc);

class SurveyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SurveyJSON: {},
      isSelectorOpen: false,
      isAutoSaveOpen: false,
      addPageResolver: null,
      removePageResolver: null,
      removeCancelled: false,
      removePage: {},
      removePageQuestions: [],
      autoSave: false,
      deleteElementResolver: null,
      deleteElementCancelled: false,
      deletedElement: {},
      deletedElementDependencies: [],
    };
  }

  creator = null;

  checkTemplateForErrors() {
    const self = this;
    setTimeout(() => {
      const status = warningTemplate(self.creator.JSON);
      const container_header_warning =
        document.getElementsByClassName("header-warning");
      if (!container_header_warning[0]) return;
      container_header_warning[0].style.display = status ? "block" : "none";
      container_header_warning[0].innerText = status;
    });
  }

  recalculateQuestionNumbers(survey) {
    //because of the complicated custom components we need artificial delay to allow it to draw in the dom
    setTimeout(() => {
      this.updatePageNumbering(survey);
    });
  }

  customDividerDefaults() {
    const divider = document.getElementsByClassName("svd_editors")[0];
    divider.style = "width: 50%; max-width: 50%; flex-basis: 50%;";
    const rightPanel = document.getElementsByClassName(
      "svd-designer-container--right-side"
    )[0];
    rightPanel.style = "width: 50%; max-width: 50%; flex-basis: 50%;";
  }

  updatePageNumbering(survey) {
    if (!survey.pages) {
      return;
    }

    for (let p = 0; p < survey.pages.length; p++) {
      const pageElements = survey.pages[p].elements;

      if (!pageElements) {
        continue;
      }
      if (Array.isArray(pageElements)) {
        let questionCounter = 1;

        for (let q = 0; q < pageElements.length; q++) {
          // if (pageElements[q].type === "expression") {
          //   continue;
          // }

          const question = pageElements[q];

          const questionName = question.name;
          const questionNumber = p + 1 + "." + questionCounter;
          const questionElement = document.querySelectorAll(
            `[data-name="${questionName}"]`
          );

          if (!questionElement) {
            continue;
          }

          for (let el = 0; el < questionElement.length; el++) {
            let numberingElement =
              questionElement[el].getElementsByClassName("sv_q_num_cust")[0];

            if (!numberingElement) {
              numberingElement = document.createElement("div");
              numberingElement.className = "sv_q_num_cust";
              questionElement[el].prepend(numberingElement);
            }

            numberingElement.innerText = `${questionNumber} ${
              question.questionNumberingOverride
                ? "(" + question.questionNumberingOverride + ")"
                : ""
            }`;
          }

          questionCounter++;
        }
      }
    }
  }

  disposeAutoSaveTimer() {
    if (window.autoSaveTimer) {
      clearInterval(window.autoSaveTimer.id);
      window.autoSaveTimer = null;
    }
  }

  autoSaveToggle(autoSave) {
    this.disposeAutoSaveTimer();

    if (autoSave && !window.autoSaveTimer) {
      const self = this;

      window.autoSaveTimer = {};
      window.autoSaveTimer.isSaved = true;

      window.autoSaveTimer.id = setInterval(() => {
        if (window.autoSaveTimer.isSaved) {
          return;
        }

        const timeNow = new Date().getTime();
        const timePassedFromLastAction = timeNow - window.lastActionTime;

        if (timePassedFromLastAction < 1000 * 5) {
          return;
        }

        auth_connector.save_template(self.creator, (err, res) => {
          if (err) {
            notifications.error_top_full(err, "Survey not saved to DB");
          }
        });

        window.autoSaveTimer.isSaved = true;
      }, 1000);

      return;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (window.autoSaveTimer) {
      window.autoSaveTimer.isSaved = false;
    }

    if (prevState.autoSave !== this.state.autoSave) {
      this.autoSaveToggle(this.state.autoSave);
    }
  }

  componentWillUnmount() {
    this.disposeAutoSaveTimer();
  }

  componentDidMount() {
    const self = this;
    let template;

    try {
      template = JSON.parse(this.props.template);
      const findCalcValueAndAssign = (surveyData) => {
        if (Array.isArray(surveyData.pages)) {
          surveyData.pages.forEach(page => {
            if (Array.isArray(page.elements)) {
              page.elements.forEach(element => {
                if (element.type === "radiogroup" && Array.isArray(element.choices)) {
                  element.choices.forEach(choice => {
                    if (choice.calcValue) {
                      choice.value = choice.calcValue;
                      // console.log(`Assigned calcValue: ${choice.calcValue} to value for ${element.name}`, choice);
                    }
                  });
                }
              });
            }
          });
        }
      };
      findCalcValueAndAssign(template)
      // console.log("Updated template",template)
    } catch (err) {
      notifications.error_top_full(
        "Template corrupted",
        "Template is not loaded"
      );
      template = {};
    }

    if (template.autoSave === undefined) {
      self.setState({ isAutoSaveOpen: true });
    }

    if (template) {
      template.showQuestionNumbers = "off";
    }

    //remove LOGO
    SurveyPlugin.Serializer.removeProperty("survey", "logo");
    SurveyPlugin.Serializer.removeProperty("survey", "logoWidth");
    SurveyPlugin.Serializer.removeProperty("survey", "logoHeight");
    SurveyPlugin.Serializer.removeProperty("survey", "logoFit");
    SurveyPlugin.Serializer.removeProperty("survey", "logoPosition");

    SurveyPlugin.Serializer.findProperty("survey", "pages").visible = false;

    SurveyPlugin.surveyLocalization.supportedLocales = supportedLocales.map(
      (m) => m.code
    );

    SurveyPlugin.Serializer.addProperty("survey", {
      name: "shortVersion:boolean",
      category: "general",
      visibleIndex: 1,
      visible: true,
    });

    SurveyPlugin.Serializer.addProperty("survey", {
      name: "autoSave:boolean",
      category: "general",
      visibleIndex: 2,
      visible: true,
    });

    SurveyPlugin.Serializer.addProperty("survey", {
      name: "calculateColumns",
      category: "general",
      visibleIndex: 2,
      visible: false,
    });

    SurveyPlugin.Serializer.addProperty("survey", {
      name: "utf8:boolean",
      category: "general",
      visibleIndex: 0,
      visible: false,
    });

    SurveyPlugin.Serializer.addProperty("question", {
      name: "excelColumnName:string",
      category: "general",
      visibleIndex: 1,
      visible: true,
    });

    SurveyPlugin.Serializer.addProperty("question", {
      name: "questionNumberingOverride:string",
      category: "general",
      visibleIndex: 2,
      visible: true,
    });

    SurveyPlugin.Serializer.addProperty("question", {
      name: "hideAnsweredStatus:boolean",
      category: "general",
    });

    SurveyPlugin.Serializer.addProperty("expression", {
      name: "isShortIncluded",
      type: "switch",
      displayName: "Is required?",
      category: "general",
      visibleIndex: 5,
    });

    SurveyPlugin.Serializer.addProperty("itemvalue", {
      name: "code",
    });

    let creatorOptions = {
      showSimulatorInTestSurveyTab: false,
      showTranslationTab: true,
      showJSONEditorTab: true,
      showToolbox: "right",
      showPropertyGrid: "right",
    };
    self.creator = new SurveyCreator.SurveyCreator(
      "creatorElement",
      creatorOptions
    );
    self.creator.showState = true;
    self.creator.isAutoSave = false;
    self.creator.showTitlesInExpressions = true;
    self.creator.showObjectTitles = true;

    self.creator.onSurveyInstanceCreated.add(function (s, o) {
      self.customDividerDefaults();

      const selectors = document
        .getElementById("surveyjsExample")
        ?.getElementsByTagName("select");

      if (selectors) {
        Array.from(selectors).forEach((selector) => {
          selector.onchange = () => {
            self.recalculateQuestionNumbers(self.creator.JSON);
          };
        });
      }

      self.checkTemplateForErrors();
    });

    self.creator.onQuestionAdded.add(function (sender, options) {
      const generatedName = "id";
      let iteration = 1;
      let unique = false;

      const questionHashNames = options.page.surveyValue.questionHashes.names;

      while (!unique) {
        if (generatedName + iteration in questionHashNames) {
          iteration++;
          continue;
        }

        unique = true;
      }

      options.question.name = generatedName + iteration;

      if (!options.question.choices) {
        return;
      }

      for (let i = 0; i < options.question.choices.length; i++) {
        self.choiceIdGenerator(
          options.question.choices,
          options.question.choices[i]
        );
      }
    });

    self.creator.onGetObjectDisplayName.add(function (sender, options) {
      if (options.reason === "page-tabs") {
        options.displayName =
          options.obj.visibleIndex + 1 + ". " + options.displayName;
      }
    });

    async function addConfirmBox() {
      return new Promise(function (resolve) {
        self.setState({ addPageResolver: resolve });
      });
    }

    self.creator.onPageAdded.add(async function (sender, options) {
      if (self.state.removeCancelled) {
        self.setState({ removeCancelled: false });
        return;
      }

      let result = await addConfirmBox();

      if (!result) {
        options.page.delete();
      }
    });

    self.creator.onGetObjectDisplayName.add(function (sender, options) {
      if (options.reason === "page-tabs") return;

      let questionIndex = -1;

      let pageIndex = sender.JSON.pages.findIndex((page) => {
        if (options.obj.getType() === "page") {
          if (page.name === options.obj.name) return true;
        }

        questionIndex = page.elements?.findIndex(
          (question) => question.name === options.obj.name
        );

        if (questionIndex !== -1) {
          return true;
        }
        return false;
      });

      options.displayName =
        (pageIndex !== -1 ? pageIndex + 1 : "") +
        (questionIndex !== -1
          ? "." +
            (questionIndex + 1) +
            (options.obj.questionNumberingOverride
              ? " (" + options.obj.questionNumberingOverride + ")"
              : "")
          : "") +
        ". [" +
        options.obj.name +
        "] " +
        options.obj.title;
    });

    self.creator.onModified.add(function (s, o) {
      if (o.type === "PROPERTY_CHANGED") {
        if (
          ["title", "name"].includes(o.name) &&
          o.target.getType() === "page"
        ) {
          const pageSelector =
            document.getElementsByClassName("svd-page-selector");
          if (pageSelector) {
            const option =
              pageSelector[0].getElementsByTagName("option")[
                o.target.visibleIndex
              ];
            option.innerText = `${o.target.visibleIndex + 1}. ${
              !o.target.title ? o.target.name : o.target.title
            }`;
          }
        }

        if (o.name === "locale" && !o.newValue) {
          s.survey.locale = "en";
        }

        self.recalculateQuestionNumbers(s.JSON);
        self.setState({
          SurveyJSON: s.JSON,
          autoSave: s.JSON.autoSave ?? false,
        });
      }
    });

    self.creator.saveSurveyFunc = function (saveNo, callback) {
      auth_connector.save_template(self.creator, (err, res) => {
        if (!err) {
          notifications.success_top_full(null, "Survey saved to DB");
          self.checkTemplateForErrors();
          !!callback && callback(saveNo, true);
        } else {
          notifications.error_top_full(err, "Survey not saved to DB");
          !!callback && callback(saveNo, false);
        }
      });
    };
    self.creator.rightContainerActiveItem("toolbox");
    self.creator.onPropertyAfterRender.add((sender, options) => {
      if (options.property.name === "name") {
        let elementType = options.obj.getType();
        if (elementType === "page" || elementType === "panel") return;
        var input = options.htmlElement; //component properies;
        let component_name =
          input.getElementsByClassName("svd-control-label")[0];
        component_name.innerHTML = "ID";
      }
    });

    self.creator?.toolbarItems?.splice(
      4,
      0,
      new SurveyPlugin.Action({
        id: "template-selector",
        visible: true,
        title: "Copy functions",
        action: () => {
          self.setState({ isSelectorOpen: true });
        },
      })
    );
    self.creator?.toolbarItems?.splice(
      8,
      0,
      new SurveyPlugin.Action({
        id: "page-deleter",
        visible: true,
        title: "Delete page",
        action: () => {
          if (self.creator.survey.pages.length === 1) return;

          self.creator.deletePage();
        },
      })
    );

    let toolbox = self.creator.toolbox;

    let toolboxComponents = [];

    const componentNames = getActiveToolboxComponents();

    componentNames.forEach((componentName) => {
      toolboxComponents.push(toolbox.getItemByName(componentName));
    });

    self.creator.toolbox.addItems(toolboxComponents, true);

    self.creator.toolbox.addItem(yesno);
    self.creator.toolbox.addItem(yesnodontknow);
    self.creator.toolbox.addItem(gender);
    self.creator.toolbox.addItem(birthdate);
    self.creator.toolbox.addItem(proceduredate);

    self.creator.text = JSON.stringify(template);

    self.recalculateQuestionNumbers(template);
    self.setState({
      SurveyJSON: template,
      autoSave: template.autoSave ?? false,
    });

    self.creator.onSurveyInstanceCreated.add(function (sender, options) {
      if (options.reason === "test") {
        options.survey.getAllQuestions().forEach((q) => {
          if (!!q.choices) {
            q.choices.forEach((item) => {
              item.locText.onGetTextCallback = (text) => {
                return (
                  text +
                  " (" +
                  (item.calcValue ?? "missing") +
                  ")[" +
                  (item.code ?? "missing") +
                  "]"
                );
              };
              item.locText.strChanged();
            });
          }
        });
      }
    });

    self.creator.survey.onCurrentPageChanged.add((o, s) => {
      self.recalculateQuestionNumbers(o);
    });

    self.creator.onItemValueAdded.add(function (sender, options) {
      self.choiceIdGenerator(options.itemValues, options.newItem);
    });

    self.creator.onElementDeleting.add(async function (sender, options) {
      let element_id = options.element.name;
      if (options.elementType === 4) {
        let dpn = getDependencies(self.creator.JSON, element_id);
        options.allowing = !dpn.length;
        let result = await onDeleteElementConfirmBox(element_id, dpn);
        if (!result && options.allowing) {
          sender.undo();
        }
      }
      if (options.elementType === 2) {
        let questions_list = getQuestionsInPage(self.creator.JSON, element_id);
        options.allowing = questions_list.length === 0;
        let result = await onDeletePageConfirmBox(element_id, questions_list);
        if (!result && options.allowing) {
          sender.undo();
        }
      }
    });

    async function onDeleteElementConfirmBox(prm, dpn) {
      return new Promise(function (resolve) {
        let elm = getQuestion(self.creator.JSON, prm);
        self.setState({
          deleteElementResolver: resolve,
          deletedElement: elm,
          deletedElementDependencies: dpn,
        });
      });
    }

    async function onDeletePageConfirmBox(prm, questions) {
      return new Promise(function (resolve) {
        let pg = questions[0];
        self.setState({
          removePageResolver: resolve,
          removePage: pg,
          removePageQuestions: questions,
        });
      });
    }

    self.hideEditingWhenInUse();

    const syntaxHighlight = (json) => {
      if (typeof json != "string") {
        json = JSON.stringify(json, undefined, 2);
      }
      json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
        function (match) {
          let cls = "number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "key";
            } else {
              cls = "string";
            }
          } else if (/true|false/.test(match)) {
            cls = "boolean";
          } else if (/null/.test(match)) {
            cls = "null";
          }
          return `<span class="${cls}">${match}</span>`;
        }
      );
    };

    //function to update the JSON and handle scroll
    function updateText() {
      const activeViewer =
        jsonViewer.style.display !== "none" ? "custom" : "default";

      const elementTop =
        activeViewer === "custom"
          ? jsonViewer.scrollTop
          : textareaContent[0].scrollTop;

      if (activeViewer === "default") {
        // custom viewer
        textareaContent[0].style.display = "none";
        jsonViewer.innerHTML = `${syntaxHighlight(textareaContent[0].value)}`;
        jsonViewer.style.display = "block";
        jsonViewer.scrollTo(0, elementTop);
        button.textContent = "Edit JSON";
      } else {
        // original viewer
        jsonViewer.style.display = "none";
        textareaContent[0].style.display = "block";
        textareaContent[0].scrollTo(0, elementTop);
        button.textContent = "Save JSON";
      }
    }

    const textareaContent = document.getElementsByClassName(
      "svd-json-editor-area"
    );
    textareaContent[0].spellcheck = false;

    // custom JSON viewer
    const jsonEditorContainer =
      document.getElementsByClassName("svd-json-editor");
    const jsonViewer = document.createElement("pre");
    jsonViewer.classList = ["svd-json-editor-area"];
    jsonViewer.innerHTML = `${syntaxHighlight(template)}`;
    jsonViewer.style.backgroundColor = "#282c34";
    jsonViewer.style.color = "#abb2bf";
    jsonViewer.style.padding = "1em";
    jsonViewer.style.borderRadius = "5px";
    jsonViewer.setAttribute(
      "data-bind",
      "textInput:koText, disable: readOnly, attr: { 'aria-label': 'JSON Editor' }"
    );
    jsonViewer.setAttribute("aria-label", "JSON Editor");

    jsonEditorContainer[0].appendChild(jsonViewer);
    textareaContent[0].style.display = "none";

    // create button to set viewer
    const button = document.createElement("button");
    button.textContent = "Edit JSON";
    button.onclick = updateText;
    button.classList = ["json-editor-btn"];
    button.style.marginBottom = "1em";
    jsonEditorContainer[0].insertBefore(button, textareaContent[0]);
  }

  choiceIdGenerator = (choiceList, choiceValueToChange) => {
    if (!choiceList || !choiceValueToChange.value) return;

    for (let index = 1; ; index++) {
      const itemValue = "item" + index.toString().padStart(3, "0");
      const found = choiceList.find((f) => (f.value ?? f) === itemValue);
      if (!found) {
        choiceValueToChange.value = itemValue;
        break;
      }
    }
  };

  hideEditingWhenInUse() {
    const self = this;
    this.creator.onGetPropertyReadOnly.add(function (sender, options) {
      if (
        options.obj?.getType() === "itemvalue" &&
        options.property.name === "value" &&
        (options.parentObj?.getType() !== "nouislider" || self.props.isActive)
      ) {
        options.readOnly = true;
      }
    });

    if (!this.props.isActive) return;

    this.creator.onSetPropertyEditorOptions.add(function (sender, options) {
      if (options.propertyName === "choices") {
        options.editorOptions.allowAddRemoveItems = false;
        options.editorOptions.allowRemoveAllItems = false;
        options.editorOptions.showTextView = false;
      }
    });

    SurveyCreator.SurveyQuestionEditorDefinition.definition[
      "itemvalue[]@choices"
    ].tabs = [{ name: "general", visible: false }];
  }

  onCloseModal = () => {
    this.setState({ isSelectorOpen: false });
  };

  onAddElement = (json) => {
    this.creator.JSON = json;
    this.recalculateQuestionNumbers(this.creator.JSON);
    this.creator.survey.onCurrentPageChanged.add((o, s) => {
      this.recalculateQuestionNumbers(o);
    });
  };

  onSelect = (result) => {
    if (result) {
      const json = { ...this.creator.JSON };
      json.autoSave = true;
      this.creator.JSON = json;
    }

    this.setState({ isAutoSaveOpen: false, autoSave: result });
  };

  onAddDecision = (result) => {
    this.state.addPageResolver(result);
    this.setState({ addPageResolver: null });
  };

  onRemoveDecision = (result) => {
    this.state.removePageResolver(result);
    this.setState({ removePageResolver: null, removeCancelled: !result });
  };

  onDeleteElement = (result) => {
    this.state.deleteElementResolver(result);
    this.setState({
      deleteElementResolver: null,
      deleteElementCancelled: !result,
      deletedElement: {},
      deletedElementDependencies: [],
    });
  };

  onDeletePage = (result) => {
    this.state.removePageResolver(result);
    this.setState({
      removePageResolver: null,
      removeCancelled: !result,
      removePage: {},
      removePageQuestions: [],
    });
  };

  render() {
    return (
      <>
        <style jsx>{`
          .string {
            color: #ce9178;
          }
          .number {
            color: #b5cea8;
          }
          .boolean {
            color: #569cd6;
          }
          .null {
            color: #569cd6;
          }
          .key {
            color: #9cdcfe;
          }
        `}</style>
        <CustomConfirm
          isOpen={this.state.addPageResolver !== null ? true : false}
          msg={"Are you sure you want to add page?"}
          onDecision={this.onAddDecision}
        />
        <AutoSavePopup
          isOpen={this.state.isAutoSaveOpen}
          onSelect={this.onSelect}
          closeModal={() => {
            this.setState({ isAutoSaveOpen: false });
          }}
        />
        <TemplateExplorer
          JSON={this.state.SurveyJSON}
          activePage={this.creator?.survey?.currentPageNo ?? 0}
          isModalOpen={this.state.isSelectorOpen}
          onAdd={this.onAddElement}
          onClose={this.onCloseModal}
        />
        <DeleteElementPopup
          type={"question"}
          isOpen={this.state.deleteElementResolver !== null ? true : false}
          element={this.state.deletedElement}
          depemdencies={this.state.deletedElementDependencies}
          onDecision={this.onDeleteElement}
        />
        <DeleteElementPopup
          type={"page"}
          isOpen={this.state.removePageResolver !== null ? true : false}
          element={this.state.removePage}
          depemdencies={this.state.removePageQuestions}
          onDecision={this.onDeletePage}
        />
        <div className="survey-editor">
          <Notification />
          <div className="header">
            <div className="logo">
              <img alt="logo" src={logo} />
            </div>
            <div className="title">SURVEY EDITOR</div>
          </div>
          <div className="header-warning" style={{ display: "none" }}></div>
          <div className="template-info">
            <h2>
              {`${this.creator?.survey?.title} [ID: ${window.uri_params?.fid}]`}
            </h2>
            <h4>{`Selected language: ${
              supportedLocales.find(
                (f) => f.code === this.creator?.survey?.locale
              )?.name ?? "english"
            }`}</h4>
          </div>
          <div id="creatorElement" />
        </div>
      </>
    );
  }
}

export default SurveyComponent;
